























































































































































































































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { germanZahl } from "@/helper/utils";

interface DataColums {
  prop: string;
  sortable?: boolean;
  headCellStyle?: any;
  bodyCellStyle?: any;
  headCellClass?: any;
  bodyCellClass?: any;
}

interface DataRows {
  [key: string]: any;
}

@Component
export default class DataTable extends Vue {
  @Prop({ default: "" }) public tableNmae!: string;
  @Prop({ default: [] }) public dataColums!: Array<DataColums>;
  @Prop({ default: [] }) public dataRows!: Array<DataRows>;
  @Prop({ default: true }) public paginatorVisible!: boolean;
  @Prop({ default: true }) public itemsInfoVisible!: boolean;
  @Prop({ default: null }) public perPage!: number | null;
  @Prop({ default: null }) public sortProp!: string;
  @Prop({ default: "asc" }) public sortDirection!: string;
  @Prop({ default: 0 }) public totalItems!: number;
  @Prop({ default: 0 }) public offsetPage!: number;
  @Prop({ default: "" }) public id!: string;
  @Prop({ default: 0 }) public sum!: string;
  @Prop({ default: false }) options!: boolean;
  @Prop({ default: false }) showChangePermission!: boolean;
  @Prop({ default: false }) showOpenKanzlei!: boolean;
  @Prop({ default: false }) openMandant!: boolean;
  @Prop({ default: false }) openAssign!: boolean;
  @Prop({ default: false }) openUnassign!: boolean;
  @Prop({ default: "Zuweisung entfernen" }) unassignLabel!: boolean;
  @Prop({ default: "Mitarbeiter zuweisen" }) empassignLabel!: boolean;
  @Prop({ default: false }) assignClients!: boolean;
  @Prop({ default: true }) noEdit!: boolean;
  @Prop({ default: false }) onDemand!: boolean;
  @Prop({ default: "Edit" }) editLabel!: boolean;
  @Prop({ default: false }) showDelete!: boolean;
  @Prop({ default: false }) deleteAgency!: boolean;
  @Prop({ default: false }) deleteRow!: boolean;
  @Prop({ default: "Delete" }) deleteLabel!: boolean;
  @Prop({ default: false }) editAgency!: boolean;
  @Prop({ default: false }) showAssignedClients!: boolean;
  @Prop({ default: false }) exportProgram!: boolean;
  @Prop({ default: "Zugewiesene Kanzlei Viewer" })
  assignedButtonLabel!: boolean;
  @Prop({ default: false }) skrZuweisenButton!: boolean;
  @Prop({ default: "SKR zuweisen" }) skrZuweisenButtonLabel!: string;

  localGermanZahl: any;

  constructor() {
    super();
    this.localGermanZahl = germanZahl;
  }

  get currentPage() {
    return this.offsetPage + 1;
  }

  get tableColums() {
    return this.dataColums || [];
  }

  get tableData(): Array<DataRows> {
    let dataRows = [...this.dataRows];
    let dataFiltered = this.dataRows.map((itm) => {
      if (itm.active == true) {
        itm.active = "ja";
      }
      if (itm.active == false) {
        itm.active = "nein";
      }
      if (itm.doexport == true) {
        itm.doexport = "ja";
      }
      if (itm.doexport == false) {
        itm.doexport = "nein";
      }
      if (itm.endyear == -1) {
        itm.endyear = "fortlaufend";
      }
      return itm;
    });
    // return dataFiltered;

    var reA = /[^a-zA-Z]/g;
    var reN = /[^0-9]/g;
    let sortAlphaNum = (a1: any, b1: any) => {
      let a = a1[this.sortProp];
      let b = b1[this.sortProp];

      // console.log("a1",a1,a1[this.sortProp])
      // console.log("b1",b1,b1[this.sortProp])
      var AInt = parseInt(a, 10);
      var BInt = parseInt(b, 10);

      if (isNaN(AInt) && isNaN(BInt)) {
        var aA = a.replace(reA, "");
        var bA = b.replace(reA, "");
        if (aA === bA) {
          var aN = parseInt(a.replace(reN, ""), 10);
          var bN = parseInt(b.replace(reN, ""), 10);
          return aN === bN ? 0 : aN > bN ? 1 : -1;
        } else {
          return aA > bA ? 1 : -1;
        }
      } else if (isNaN(AInt)) {
        //A is not an Int
        return 1; //to make alphanumeric sort first return -1 here
      } else if (isNaN(BInt)) {
        //B is not an Int
        return -1; //to make alphanumeric sort first return 1 here
      } else {
        return AInt > BInt ? 1 : -1;
      }
    };
    // let sortData = (!this.sortProp || !this.sortDirection) ? dataFiltered : dataFiltered.sort(sortAlphaNum)
    let sortData =
      !this.sortProp || !this.sortDirection
        ? dataFiltered
        : dataFiltered.sort((a: DataRows, b: DataRows) => {
            if (this.sortDirection === "asc") {
              if (
                a[this.sortProp] == null ||
                a[this.sortProp] == "" ||
                b[this.sortProp] == null ||
                b[this.sortProp] == ""
              )
                return 1;
              if (isNaN(a[this.sortProp])) {
                return a[this.sortProp].toString().toLowerCase() >
                  b[this.sortProp].toString().toLowerCase()
                  ? 1
                  : -1;
              } else {
                return parseInt(a[this.sortProp]) > parseInt(b[this.sortProp])
                  ? 1
                  : -1;
              }
            } else if (this.sortDirection === "desc") {
              if (
                a[this.sortProp] == null ||
                a[this.sortProp] == "" ||
                b[this.sortProp] == null ||
                b[this.sortProp] == ""
              )
                return -1;
              if (isNaN(a[this.sortProp])) {
                return a[this.sortProp].toString().toLowerCase() >
                  b[this.sortProp].toString().toLowerCase()
                  ? -1
                  : 1;
              } else {
                return parseInt(a[this.sortProp]) > parseInt(b[this.sortProp])
                  ? -1
                  : 1;
              }
            } else {
              return 0;
            }
          });

    // console.log('this.dataRows',this.dataRows,this.sortProp)
    // console.log('this.this.tableNmae',this.tableNmae)
    // if (this.perPage && this.tableNmae != "opos") {
    //   // console.log('this.startItem',this.startItem,'this.endtItem',this.endtItem,'totalItems',this.totalItems)
    //   return sortData.slice(this.startItem, this.endtItem);
    // }

    return dataFiltered;
  }

  get startItem() {
    if (this.onDemand && this.perPage) {
      return this.perPage;
    }
    if (this.perPage) {
      return this.endtItem - this.perPage;
    }
    return 0;
  }

  get endtItem() {
    if (this.onDemand) {
      return this.totalItems;
    }

    let tmpTotalItesm =
      this.totalItems > 0 ? this.totalItems : this.dataRows.length;
    if (this.perPage && tmpTotalItesm) {
      return this.currentPage * this.perPage;
    }

    return tmpTotalItesm;
  }

  // get totalItems() {
  //     return this.dataRows ? this.dataRows.length : 0;
  // }

  changeSort(val: string): void {
    this.$emit("changeSort");
    if (this.sortProp === val) {
      let sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      this.$emit("changeSortDirection", sortDirection);
    } else {
      this.$emit("changeSortProperty", val);
      // this.sortProp = val;
      // this.sortDirection = 'asc';
    }
  }

  changePerPage(val: any): void {
    this.$emit("changePerPage", val);
  }

  @Emit("onEdit")
  onEdit(item: any) {
    return item;
  }

  @Emit("onChangePermission")
  onChangePermission(item: any) {
    return item;
  }

  @Emit("onDelete")
  onDelete(item: any) {
    return item;
  }

  @Emit("onAssignEmployee")
  onAssignEmployee(item: any) {
    return item;
  }
  @Emit("onUnassignEmployee")
  onUnassignEmployee(item: any) {
    return item;
  }

  @Emit("onAssignClients")
  onAssignClients(item: any) {
    return item;
  }

  @Emit("onShowAssignedClients")
  onShowAssignedClients(item: any) {
    return item;
  }
  @Emit("onSkrZuweisen")
  onSkrZuweisen(item: any) {
    return item;
  }
  @Emit("onExportProgram")
  onExportProgram(item: any) {
    return item;
  }
}
